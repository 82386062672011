// src/hooks/useTokenBalance.js
import { useState, useEffect } from "react";
import { Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { useWallet } from "@solana/wallet-adapter-react";

const useTokenBalance = () => {
  const [balance, setBalance] = useState(0);
  const [solBalance, setSolBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { publicKey } = useWallet();

  useEffect(() => {
    const getBalance = async () => {
      const connection = new Connection("https://api.devnet.solana.com");
      try {
        setIsLoading(true);

        if (!publicKey) {
          throw new Error("No public key available");
        }

        const token = new PublicKey(
          "9fPWJYCDMx3DBCqEpvTKiffQbXg7eyrFrifAJUJRKohP"
        );
        const userAta = await getAssociatedTokenAddress(token, publicKey);
        const tokenBalance = await connection.getTokenAccountBalance(userAta);

        setBalance(tokenBalance.value.uiAmount);

        const balanceInLamports = await connection.getBalance(publicKey);
        const balanceInSol = balanceInLamports / LAMPORTS_PER_SOL;

        setSolBalance(balanceInSol);

        setIsLoading(false);
      } catch (error) {
        console.error("Error getting balance:", error);
        setIsLoading(false);
      }
    };

    getBalance();
  }, [publicKey]);

  return { balance, isLoading, solBalance };
};

export default useTokenBalance;
