import React, { act } from "react";
import { Modal, Input, Button, Form, Row, Col } from "antd";
import { CustomModalStyled } from "./styles";
import useTokenBalance from "../../hook/useTokenBalance";
import useFetchStakeAccountData from "../../hook/useFetchStakeAccountData";
import { ReloadOutlined } from "@ant-design/icons";
import { CommonButton } from "../../styles/globleStyles";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

const CustomModal = ({
  visible,
  onCancel,
  onStakeAndDeposit,
  title,
  action,
  onUnstakeAll,
  amount,
  total,
}) => {
  const [form] = Form.useForm();
  const numberRegex = /^[0-9]*\.?[0-9]+$/;
  const { balance: tokenBalance } = useTokenBalance();
  const { stackAmountDetails, fetchData } = useFetchStakeAccountData();
  console.log("total------>>>>>>", tokenBalance, total);
  const validateNotZero = (_, value) => {
    if (value === "0") {
      return Promise.reject(new Error("Value cannot be zero!"));
    }
    if (value > Number(total) && action === "unstake") {
      return Promise.reject(
        new Error("Value cannot be greater then total staked amount!")
      );
    }
    if (value > tokenBalance && action === "stake") {
      return Promise.reject(
        new Error("Value cannot be greater then current balance!")
      );
    }
    return Promise.resolve();
  };

  console.log(
    "🚀 ~ stackAmountDetails_______________________________:",
    stackAmountDetails
  );

  const tokenCRDBalance =
    stackAmountDetails?.depositAmount?.toString() || amount || "";

  const handleAction = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const amount = parseFloat(values.amount);
      console.log("amount from handleaction", amount);
      if (action === "stake") {
        await onStakeAndDeposit(amount);
      } else {
        await onUnstakeAll(amount);
      }
      form.resetFields();
      onCancel();
    } catch (errorInfo) {
      console.log("Validation failed:", errorInfo);
    }
  };

  const handleFillAmount = (amount) => {
    console.log("amount", total);
    form.setFieldsValue({ amount: amount.toString() });
  };

  return (
    <>
      <CustomModalStyled
        getPopupContainer={(trigger) => trigger.parentNode}
        getContainer={false}
        visible={visible}
        onCancel={onCancel}
        footer={false}
      >
        <div className="title">
          <h2 style={{ padding: "0px 20px", textAlign: "center" }}>{title}</h2>
        </div>
        <div className="all-stack" style={{ marginBottom: 16 }}>
          <Row align="middle" justify="start">
            <Col>
              <strong>
                {action === "stake" ? "Current Balance" : "Staked Amount"}
              </strong>
              {action === "stake" ? tokenBalance : total !== "NaN" ? total : 0}
              &nbsp;CRD Token
              {action === "stake" ? (
                ""
              ) : (
                <div className="reload" onClick={fetchData}>
                  <ReloadOutlined />
                </div>
              )}
            </Col>
            <Col>
              <Button
                type="default"
                onClick={() =>
                  handleFillAmount(action === "stake" ? tokenBalance : total)
                }
              >
                {action === "stake" ? "Stake All" : "Unstake All"}
              </Button>
            </Col>
          </Row>
        </div>
        <Form form={form}>
          <Form.Item
            name="amount"
            rules={[
              {
                required: true,
                message: `Please enter the amount to ${action}!`,
              },
              {
                pattern: numberRegex,
                message: "Please enter a valid number!",
              },
              {
                validator: validateNotZero,
              },
            ]}
          >
            <Input
              min={0}
              type="number"
              placeholder={`Enter amount to ${action}`}
            />
          </Form.Item>
        </Form>
        <div className="button-container">
          <CommonButton
            bordered="true"
            color="black"
            width="200px"
            key="cancel"
            onClick={onCancel}
          >
            Cancel
          </CommonButton>

          <CommonButton
            key={action}
            primary
            width="200px"
            onClick={handleAction}
          >
            {action.charAt(0).toUpperCase() + action.slice(1)}
          </CommonButton>
        </div>
      </CustomModalStyled>
    </>
  );
};

export default CustomModal;
